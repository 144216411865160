import React, { useEffect, useState } from "react"
import styled from "styled-components"
import {
  Wrapper,
  PiesWrapper,
  PieWrapper,
  PieTitle,
} from "./StatistiquesUtilisateurs.module.css"
import Chart from "chart.js/auto"
import { calculatePercentage } from "./../../../../utils/statistics"
import { calculateAge } from "./../../../../utils/date"

const MostActiveUsersWrapper = styled.section`
  margin-top: 100px;

  p {
    margin-left: 20px;

    :first-child {
      font-weight: bold;
      margin-left: 0px;
      margin-bottom: 10px;
    }
  }
`

const EXCLUDE_USERS = [
  "gualtieri.hugo@hotmail.com",
  "thomas.freemium@gmail.com",
  "thomas.mensuel@gmail.com",
  "thomas.annuel@gmail.com",
  "ardito.thomas13@gmail.com",
  "thomas.wynit@gmail.com",
  "ardito.thomas11@gmail.com",
  "m_ardito@orange.fr",
  "aniceeperez@yahoo.com",
  "lainem615@gmail.com",
]

const StatistiquesUtilisateurs = ({ users }) => {
  const [mostActiveUsers, setMostActiveUsers] = useState([])
  var genderChartRef = React.createRef()
  var ageChartRef = React.createRef()
  var locationChartRef = React.createRef()

  const genderData = {
    labels: ["Femmes", "Hommes"],
    datasets: [
      {
        label: "Sexe des utilisateurs en pourcentage.",
        data: getGenderNumbers(),
        backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
        hoverOffset: 4,
      },
    ],
  }

  const genderConfig = {
    type: "pie",
    data: genderData,
  }

  const ageData = {
    labels: [
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ],
    datasets: [
      {
        label: "Nombre",
        data: getAgeNumbers(),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
        ],
        borderWidth: 1,
      },
    ],
  }

  const ageConfig = {
    type: "bar",
    data: ageData,
  }

  const locationData = {
    labels: [
      "Marseille",
      "Aix en provence",
      "Vitrolles",
      "La Ciotat",
      "Autres",
    ],
    datasets: [
      {
        label: "Localisation des utilisateurs en pourcentage.",
        data: getLocationNumbers(),
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(54, 262, 135)",
          "rgb(200, 62, 135)",
          "rgb(100, 62, 200)",
        ],
        hoverOffset: 4,
      },
    ],
  }

  const locationConfig = {
    type: "pie",
    data: locationData,
  }

  useEffect(() => {
    const genderContext = genderChartRef.current.getContext("2d")
    const ageContext = ageChartRef.current.getContext("2d")
    const locationContext = locationChartRef.current.getContext("2d")
    new Chart(genderContext, genderConfig)
    new Chart(ageContext, ageConfig)
    new Chart(locationContext, locationConfig)
    getMostActiveUsers()
  }, [])

  const pickHighest = (obj, num = 1) => {
    const requiredObj = {}
    if (num > Object.keys(obj).length) {
      return false
    }
    Object.keys(obj)
      .sort((a, b) => obj[b] - obj[a])
      .forEach((key, ind) => {
        if (ind < num) {
          requiredObj[key] = obj[key]
        }
      })
    return Object.keys(requiredObj)
  }

  function getMostActiveUsers() {
    var idx = 0
    var usersActivity = {}
    var result = []

    while (idx < users.length) {
      if (!EXCLUDE_USERS.includes(users[idx]["email"])) {
        usersActivity[users[idx].id] = users[idx]["usedOffers"].length
      }
      idx++
    }
    var mostActiveUsers = pickHighest(usersActivity, 20)
    //console.log(mostActiveUsers);
    idx = 0
    while (idx < users.length) {
      if (mostActiveUsers.includes(users[idx].id)) result.push(users[idx])
      idx++
    }
    //console.log(result);
    setMostActiveUsers(result)
  }

  function getGenderNumbers() {
    var idx = 0
    var men = 0
    var women = 0

    while (idx < users.length) {
      if (users[idx]["gender"] === "Homme") men++
      else women++
      idx++
    }
    return [women, men]
  }

  function getAgeNumbers() {
    var idx = 0
    var result = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

    while (idx < users.length) {
      var age = calculateAge(users[idx]["birthDate"] / 1000)
      if (age >= 15 && age <= 30) {
        result[age - 15]++
      }
      idx++
    }
    return result
  }

  function getLocationNumbers() {
    var idx = 0
    var marseille = 0
    var aix = 0
    var vitrolles = 0
    var ciotat = 0
    var other = 0
    const MARSEILLE_POSTAL_CODE = [
      "13000",
      "13001",
      "13002",
      "13003",
      "13004",
      "13005",
      "13006",
      "13007",
      "13008",
      "13009",
      "13010",
      "13011",
      "13012",
      "13013",
      "13014",
      "13015",
    ]
    const AIX_POSTAL_CODE = [
      "13080",
      "13090",
      "13098",
      "13100",
      "13290",
      "13540",
    ]
    const VITROLLES_POSTAL_CODE = ["13127"]
    const CIOTAT_POSTAL_CODE = ["13600"]

    while (idx < users.length) {
      if (users[idx]["postalCode"].length !== 5) {
        other++
      } else if (
        MARSEILLE_POSTAL_CODE.includes(users[idx]["postalCode"]) === true
      ) {
        marseille++
      } else if (AIX_POSTAL_CODE.includes(users[idx]["postalCode"]) === true) {
        aix++
      } else if (
        VITROLLES_POSTAL_CODE.includes(users[idx]["postalCode"]) === true
      ) {
        vitrolles++
      } else if (
        CIOTAT_POSTAL_CODE.includes(users[idx]["postalCode"]) === true
      ) {
        ciotat++
      } else {
        other++
      }
      idx++
    }

    return [marseille, aix, vitrolles, ciotat, other]
  }

  return (
    <div className={Wrapper}>
      <p>
        Nombre total d'utilisateurs de l'application: <b>{users.length}</b>
      </p>
      <div className={PiesWrapper}>
        <div className={PieWrapper}>
          <p className={PieTitle}>Sexe</p>
          <canvas id="genderChartId" ref={genderChartRef} />
          <p>
            Hommes: {calculatePercentage(getGenderNumbers()[1], users.length)}%
          </p>
          <p>
            Femmes: {calculatePercentage(getGenderNumbers()[0], users.length)}%
          </p>
        </div>
        <div className={PieWrapper}>
          <p className={PieTitle}>Âge</p>
          <canvas id="ageChartId" ref={ageChartRef} />
          <p>15ans: {calculatePercentage(getAgeNumbers()[0], users.length)}%</p>
          <p>16ans: {calculatePercentage(getAgeNumbers()[1], users.length)}%</p>
          <p>17ans: {calculatePercentage(getAgeNumbers()[2], users.length)}%</p>
          <p>18ans: {calculatePercentage(getAgeNumbers()[3], users.length)}%</p>
          <p>19ans: {calculatePercentage(getAgeNumbers()[4], users.length)}%</p>
          <p>20ans: {calculatePercentage(getAgeNumbers()[5], users.length)}%</p>
          <p>21ans: {calculatePercentage(getAgeNumbers()[6], users.length)}%</p>
          <p>22ans: {calculatePercentage(getAgeNumbers()[7], users.length)}%</p>
          <p>23ans: {calculatePercentage(getAgeNumbers()[8], users.length)}%</p>
          <p>24ans: {calculatePercentage(getAgeNumbers()[9], users.length)}%</p>
          <p>
            25ans: {calculatePercentage(getAgeNumbers()[10], users.length)}%
          </p>
          <p>
            26ans: {calculatePercentage(getAgeNumbers()[11], users.length)}%
          </p>
          <p>
            27ans: {calculatePercentage(getAgeNumbers()[12], users.length)}%
          </p>
          <p>
            28ans: {calculatePercentage(getAgeNumbers()[13], users.length)}%
          </p>
          <p>
            29ans: {calculatePercentage(getAgeNumbers()[14], users.length)}%
          </p>
          <p>
            30ans: {calculatePercentage(getAgeNumbers()[15], users.length)}%
          </p>
        </div>
        <div className={PieWrapper}>
          <p className={PieTitle}>Localisation</p>
          <canvas id="locationChartId" ref={locationChartRef} />
          <p>
            Marseille:{" "}
            {calculatePercentage(getLocationNumbers()[0], users.length)}%
          </p>
          <p>
            Aix en provence:{" "}
            {calculatePercentage(getLocationNumbers()[1], users.length)}%
          </p>
          <p>
            Vitrolles:{" "}
            {calculatePercentage(getLocationNumbers()[2], users.length)}%
          </p>
          <p>
            La Ciotat:{" "}
            {calculatePercentage(getLocationNumbers()[3], users.length)}%
          </p>
          <p>
            Autres villes:{" "}
            {calculatePercentage(getLocationNumbers()[4], users.length)}%
          </p>
        </div>
      </div>
      <MostActiveUsersWrapper>
        <p>20 utilisateurs les plus actifs: </p>
        {mostActiveUsers.map((user, idx = 0) => (
          <p>
            {idx + 1}. {user["firstName"]} {user["lastName"]}, {user["email"]}.
            Offres utilisées: {user["usedOffers"].length}
          </p>
        ))}
      </MostActiveUsersWrapper>
    </div>
  )
}

export default StatistiquesUtilisateurs
