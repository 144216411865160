import React from "react"
import {
  Wrapper,
  SectionWrapper
} from "./Statistiques.module.css"
import { OrangeTitle } from "./../../../css/Titre.module.css"
import Title from "./../../../components/Title";
import StatistiquesUtilisateurs from "./StatistiquesUtilisateurs";
import StatistiquesEtablissements from "./StatistiquesEtablissements";
import StatistiquesOffres from "./StatistiquesOffres";

const Statistiques = ({users, shops, offers}) => {

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Statistiques</h1>
      <div className={SectionWrapper}>
        <Title title={"Utilisateurs"} />
        <StatistiquesUtilisateurs users={users} />
      </div>
      <div className={SectionWrapper}>
        <Title title={"Établissements"} />
        <StatistiquesEtablissements shops={shops}/>
      </div>
      <div className={SectionWrapper}>
        <Title title={"Offres"} />
        <StatistiquesOffres users={users} offers={offers}/>
      </div>
    </div>
  )
}

export default Statistiques
