import React, { useState, useEffect } from "react";
import Layout from "./../../../components/Layout";
import Statistiques from "./../../../nav/Admin/Statistiques";
import { Helmet } from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import { getUser } from "./../../../auth";
import Error403 from "./../../../nav/Error403";
import { TailSpin } from "react-loader-spinner";
import {
  getUserFormatted,
  getShopFormatted,
  getOfferFormatted,
} from "./../../../utils/formating";

const HandleUserStates = ({ user, users, shops, offers }) => {
  if (user === undefined) {
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else if (user && user.subscription.type === "admin") {
    return <Statistiques users={users} shops={shops} offers={offers} />;
  } else {
    return <Error403 />;
  }
};

const StatistiquesPage = () => {
  const [user, setUser] = useState(undefined);
  const [users, setUsers] = useState([]);
  const [shops, setShops] = useState([]);
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    getUser(setUser);
    firebase
      .firestore()
      .collection("users")
      .get()
      .then((docs) => {
        let idx = 0;
        let newUsers = [];

        while (idx < docs.docs.length) {
          newUsers.push(getUserFormatted(docs.docs[idx]));
          idx++;
        }
        setUsers(newUsers);
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
    firebase
      .firestore()
      .collection("shops")
      .get()
      .then((docs) => {
        let idx = 0;
        let newShops = [];

        while (idx < docs.docs.length) {
          newShops.push(getShopFormatted(docs.docs[idx]));
          idx++;
        }
        setShops(newShops);
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
    firebase
      .firestore()
      .collection("offers")
      .get()
      .then((docs) => {
        let idx = 0;
        let newOffers = [];

        while (idx < docs.docs.length) {
          newOffers.push(getOfferFormatted(docs.docs[idx]));
          idx++;
        }
        setOffers(newOffers);
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Statistiques</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Tu trouveras ici un formulaire te permettant d'ajouter un commerce."
        />
        <meta name="keywords" content="réductions,jeunes,admin,commerce" />
        <link rel="canonical" href="https://wynit.com/admin/statistiques" />
      </Helmet>
      <HandleUserStates
        user={user}
        users={users}
        shops={shops}
        offers={offers}
      />
    </Layout>
  );
};

export default StatistiquesPage;
