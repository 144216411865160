import React from "react"
import {
  Wrapper,
  RowWrapper
} from "./StatistiquesOffres.module.css"
import OfferCard from "./../../../../components/OfferCard"

const EXCLUDE_USERS = [
  "gualtieri.hugo@hotmail.com",
  "thomas.freemium@gmail.com",
  "thomas.mensuel@gmail.com",
  "thomas.annuel@gmail.com",
  "ardito.thomas13@gmail.com",
  "thomas.wynit@gmail.com",
  "ardito.thomas11@gmail.com",
  "m_ardito@orange.fr",
  "aniceeperez@yahoo.com",
  "lainem615@gmail.com"
]

const DisplayMostUsedOffers = ({offers}) => {
  return (
    <div className={RowWrapper}>
      <div>
        <OfferCard
          image={offers[0]["image"]}
          date={""}
          category={offers[0]["category"]}
          shopName={offers[0]["shopName"]}
          title={offers[0]["title"]}
        />
        <p>Utilisée {offers[0]["numberOfUse"]} fois</p>
      </div>
      <div>
        <OfferCard
          image={offers[1]["image"]}
          date={""}
          category={offers[1]["category"]}
          shopName={offers[1]["shopName"]}
          title={offers[1]["title"]}
        />
        <p>Utilisée {offers[1]["numberOfUse"]} fois</p>
      </div>
      <div>
        <OfferCard
          image={offers[2]["image"]}
          date={""}
          category={offers[2]["category"]}
          shopName={offers[2]["shopName"]}
          title={offers[2]["title"]}
        />
        <p>Utilisée {offers[2]["numberOfUse"]} fois</p>
      </div>
      <div>
        <OfferCard
          image={offers[3]["image"]}
          date={""}
          category={offers[3]["category"]}
          shopName={offers[3]["shopName"]}
          title={offers[3]["title"]}
        />
        <p>Utilisée {offers[3]["numberOfUse"]} fois</p>
      </div>
      <div>
        <OfferCard
          image={offers[4]["image"]}
          date={""}
          category={offers[4]["category"]}
          shopName={offers[4]["shopName"]}
          title={offers[4]["title"]}
        />
        <p>Utilisée {offers[4]["numberOfUse"]} fois</p>
      </div>
      <div>
        <OfferCard
          image={offers[5]["image"]}
          date={""}
          category={offers[5]["category"]}
          shopName={offers[5]["shopName"]}
          title={offers[5]["title"]}
        />
        <p>Utilisée {offers[5]["numberOfUse"]} fois</p>
      </div>
      <div>
        <OfferCard
          image={offers[6]["image"]}
          date={""}
          category={offers[6]["category"]}
          shopName={offers[6]["shopName"]}
          title={offers[6]["title"]}
        />
        <p>Utilisée {offers[6]["numberOfUse"]} fois</p>
      </div>
      <div>
        <OfferCard
          image={offers[7]["image"]}
          date={""}
          category={offers[7]["category"]}
          shopName={offers[7]["shopName"]}
          title={offers[7]["title"]}
        />
        <p>Utilisée {offers[7]["numberOfUse"]} fois</p>
      </div>
      <div>
        <OfferCard
          image={offers[8]["image"]}
          date={""}
          category={offers[8]["category"]}
          shopName={offers[8]["shopName"]}
          title={offers[8]["title"]}
        />
        <p>Utilisée {offers[8]["numberOfUse"]} fois</p>
      </div>
      <div>
        <OfferCard
          image={offers[9]["image"]}
          date={""}
          category={offers[9]["category"]}
          shopName={offers[9]["shopName"]}
          title={offers[9]["title"]}
        />
        <p>Utilisée {offers[9]["numberOfUse"]} fois</p>
      </div>
    </div>
  );
}


const StatistiquesOffres = ({offers, users}) => {
  function getOffersUsedLast7Days() {
    var i = 0
    var j = 0
    var now = Date.now() * 1000
    var result = 0

    while (i < users.length) {
      while (j < users[i]["usedOffers"].length) {
        if (!EXCLUDE_USERS.includes(users[i]["email"]) && users[i]["usedOffers"][j]["date"] > (now - 604800000000))
          result++
        j++
      }
      i++
      j = 0
    }
    return (result)
  }

  function getOffersUsedLast30Days() {
    var i = 0
    var j = 0
    var now = Date.now() * 1000
    var result = 0

    while (i < users.length) {
      while (j < users[i]["usedOffers"].length) {
        if (!EXCLUDE_USERS.includes(users[i]["email"]) && users[i]["usedOffers"][j]["date"] > (now - (604800000000 * 4)))
          result++
        j++
      }
      i++
      j = 0
    }
    return (result)
  }

  const pickHighest = (obj, num = 1) => {
    const requiredObj = {};
    if(num > Object.keys(obj).length){
       return false;
    };
    Object.keys(obj).sort((a, b) => obj[b] - obj[a]).forEach((key, ind) =>
    {
       if(ind < num){
          requiredObj[key] = obj[key];
       }
    });
    return Object.keys(requiredObj);
  };

  function getMostUsedOffers() {
    var offersUtilisation = {};
    var i = 0
    var j = 0

    while (i < users.length) {
      while (j < users[i]["usedOffers"].length) {
        if (offersUtilisation[users[i]["usedOffers"][j]["id"]] >= 1) {
          offersUtilisation[users[i]["usedOffers"][j]["id"]]++
        }
        else {
          offersUtilisation[users[i]["usedOffers"][j]["id"]] = 1
        }
        j++
      }
      i++
      j = 0
    }
    var mostUsedOffers = pickHighest(offersUtilisation, 10);

    i = 0
    j = 0

    var result = []
    while (i < offers.length) {
      if (mostUsedOffers.includes(offers[i]["id"]))
        result.push(offers[i])
      i++
    }

    i = 0
    while (i < result.length) {
      result[i]["numberOfUse"] = offersUtilisation[result[i]["id"]]
      i++
    }
    return (result)
  }

  return (
    <div className={Wrapper}>
      <p>Nombre total d'offres: <b>{offers.length}</b></p>
      <p>Offres utilisées sur les 7 derniers jours: {getOffersUsedLast7Days()}</p>
      <p>Offres utilisées sur les 30 derniers jours: {getOffersUsedLast30Days()}</p>
      <p>Offres les + utilisées:</p>
      <DisplayMostUsedOffers offers={getMostUsedOffers()}/>
    </div>
  )
}

export default StatistiquesOffres
