import React from "react"
import {
  Wrapper,
} from "./StatistiquesEtablissements.module.css"

const StatistiquesEtablissements = ({shops}) => {

  function getNumberOfShops() {
    var idx = 0
    var result = 0

    while (idx < shops.length) {
      if (shops[idx]["category"] !== "Jobs")
        result++
      idx++;
    }
    return result
  }

  function getCategoryShopsNumber(category) {
    var idx = 0
    var result = 0

    while (idx < shops.length) {
      if (shops[idx]["category"] === category)
        result++
      idx++;
    }
    return result
  }

  return (
    <div className={Wrapper}>
      <p>Nombre total d'établissements: <b>{getNumberOfShops()}</b></p>
      <p>Nombre de partenaires dans la section Bars & Restauration: {getCategoryShopsNumber("Bars & Restauration")}</p>
      <p>Nombre de partenaires dans la section Sports & Loisirs: {getCategoryShopsNumber("Sports & Loisirs")}</p>
      <p>Nombre de partenaires dans la section Mode & Bien-être: {getCategoryShopsNumber("Mode & Bien être")}</p>
      <p>Nombre de partenaires dans la section Services: {getCategoryShopsNumber("Services")}</p>
    </div>
  )
}

export default StatistiquesEtablissements
